import React from "react";

const Infrastructure = () => {
  return (
    <>
      <div className="container mt-3">
        <h2>Infrastructure Highlights</h2>
        <hr />
        <p>
          <b>Cutting-edge Technology:</b> Our facilities are equipped with the
          latest technology, ensuring that the rubber mixing and moulding
          processes are at the forefront of industry advancements.
        </p>
        <p>
          <b>Quality Assurance:</b> Rigorous quality control measures are
          embedded in our processes, guaranteeing that each product from the
          Rubber Mixing Mill and Vacuum Compression moulding adheres to the
          highest quality standards.
        </p>
        <p>
          <b>Customization Capabilities:</b> Tailor your rubber components to
          meet specific requirements. Our facilities are designed for
          flexibility, allowing for the customization of compounds and moulded
          products to precise specifications.
        </p>
        <p>
          <b> Automation Integration: </b>To enhance efficiency, our facilities
          integrate automation in key processes. This automation is exemplified
          in applications like automotive manufacturing, where the precise
          automation of rubber mixing and moulding ensures consistency and
          reduces production time.
        </p>
        <p>
          <b>Supply Chain Visibility:</b> Our infrastructure prioritizes supply
          chain visibility, allowing clients to trace the journey of their
          products from raw materials to the final output. This transparency is
          critical in industries like pharmaceuticals, where traceability and
          accountability are paramount for regulatory compliance.
        </p>
      </div>
      <div className="container mt-5">
        <h2>Our Facilities</h2>
        <hr />
        <p>
          Explore the capabilities of our in-house compounding facility, where
          expertise, customization, and innovation converge to create rubber
          compounds that set industry standards.
        </p>

        <table className="table table-striped border">
          <tbody>
            <tr>
              <td>Aspect</td>
              <td>Description</td>
            </tr>
            <tr>
              <td>Mixing Technology</td>
              <td>
                Advanced technology for precise blending of raw rubb er
                materials and additives.
              </td>
            </tr>
            <tr>
              <td>Raw Material Expertise</td>
              <td>
                Staffed with professionals experienced in handling diverse raw
                rubber materials.
              </td>
            </tr>
            <tr>
              <td>Customization Capabilities</td>
              <td>
                Flexibility to customize compound formulations based on client
                requirements.
              </td>
            </tr>
            <tr>
              <td>Quality Control Measures</td>
              <td>
                Rigorous checks at every stage to ensure compounds meet or
                exceed industry standards.
              </td>
            </tr>
            <tr>
              <td>Material Traceability</td>
              <td>
                Implements a robust system for material traceability, allowing
                clients to trace the origin and processing history of each batch
                of rubber compounds.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="container">
        <div className="mt-5">
          <div className="row my-5">
            <div className="col-md-6  text-center">
              <img
                src="https://img1.wsimg.com/isteam/ip/ea607c1e-4afe-4532-98df-4b0ad7c2cd64/blob-d6cae3f.png/:/cr=t:12.51%25,l:0%25,w:100%25,h:74.99%25/rs=w:600,h:300,cg:true"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <h4 className="text-uppercase py-3">
                Rubber Mixing Mill &amp; Kneader
              </h4>
              <p
                className="text-muted"
                data-aos="fade-left"
                style={{ textAlign: "justify" }}
              >
                Discover the heart of our manufacturing expertise – the advanced
                Rubber Mixing Mill. Fitted with top-notch technology, it
                skilfully blends raw rubber materials with additives, creating
                consistent compounds crucial for various uses. With a keen focus
                on quality, our Rubber Mixing Mill ensures each batch meets
                strict industry standards. Whether it&#39;s for automotive
                parts, industrial seals, or special applications, count on our
                mill to deliver top-quality results every time.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="row my-5">
            <div className="col-md-6">
              <h4 className="text-uppercase py-3">
                Vacuum Compression Moulding
              </h4>
              <p className="text-muted" style={{ textAlign: "justify" }}>
                Experience precision in rubber shaping with our advanced Vacuum
                Compression moulding facility. This technology enables us to
                produce intricate rubber components with unmatched accuracy and
                detail. By utilizing vacuum technology during the moulding
                process, we achieve superior material distribution, resulting in
                products that meet the most demanding specifications. From
                automotive parts to medical devices, our Vacuum Compression
                moulding capabilities allow us to cater to a diverse range of
                industries, ensuring that your rubber components are not just
                moulded but crafted with precision.
              </p>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="https://img1.wsimg.com/isteam/ip/ea607c1e-4afe-4532-98df-4b0ad7c2cd64/blob-58d7c4c.png/:/cr=t:28.2%25,l:14.5%25,w:58.14%25,h:43.6%25/rs=w:600,h:300,cg:true,m"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Infrastructure;
