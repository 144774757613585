import React from "react";
import "./footer.css";
import {
  FaAddressBook,
  FaFacebook,
  FaGithub,
  FaMailBulk,
  FaPhone,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import Marquee from "react-fast-marquee";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="pg-footer mt-3">
        <footer className="footer">
          <svg
            className="footer-wave-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 100"
            preserveAspectRatio="none"
          >
            <path
              className="footer-wave-path"
              d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
            ></path>
          </svg>
          <div className="footer-content">
            <div className="footer-content-column">
              <div className="footer-logo">
                <a className="footer-logo-link" href="#">
                  <span className="hidden-link-text"></span>
                  <img src="/logo.jpeg" width={200} alt="" />
                </a>
              </div>
              <div className="footer-menu">
                <h2 className="footer-menu-name">
                  CKI Rubber Technologies <br />
                  (I) Private Ltd.
                </h2>
                <p className="my-2" style={{ textAlign: "justify" }}>
                  Welcome to CKI Rubber Technologies (I) Pvt Ltd, the pinnacle
                  of innovation, quality, and sustainability in the rubber
                  industry. We are thrilled to connect with you and share the
                  passion that propels our journey.{" "}
                </p>
              </div>
            </div>
            <div className="footer-content-column">
              <div className="footer-menu">
                <h2 className="footer-menu-name"> Quick Links</h2>
                <ul id="menu-quick-links" className="footer-menu-list">
                  <li className="menu-item menu-item-type-custom menu-item-object-custom">
                    <NavLink target="_blank" rel="noopener noreferrer" to="/">
                      Home
                    </NavLink>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom">
                    <NavLink
                      target="_blank"
                      rel="noopener noreferrer"
                      to="/about"
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li className="menu-item menu-item-type-taxonomy menu-item-object-category">
                    <NavLink to="/gallery">Gallery</NavLink>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page">
                    <NavLink to="/contact">Contact</NavLink>
                  </li>
                </ul>
              </div>
              <div className="footer-menu">
                <h2 className="footer-menu-name"> Services</h2>
                <ul id="menu-legal" className="footer-menu-list">
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                    <NavLink to="/compounding">
                      Rubber Compounding & Mixing
                    </NavLink>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page">
                    <NavLink to="/moulding">Rubber Moulding</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-content-column">
              <div className="footer-menu">
                <h2 className="footer-menu-name"> Address</h2>
                <p>
                  <FaAddressBook className="me-2" />
                  Plot No D - 296, D Block, MIDC Ranjangaon, A/P- Karegaon, Tal,
                  Dist, Shirur, Maharashtra 412220
                </p>
                <p>
                  <FaPhone /> +91 9172204506/+91 9172204502/+91 9172204508
                </p>
                <p>
                  <FaMailBulk /> enquiry@ckirubber.com
                </p>
              </div>
            </div>
            <div className="footer-content-column">
              <div className="footer-call-to-action">
                <h2 className="footer-call-to-action-title">Get Direction</h2>

                <div className="iframe_container mt-2">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3777.2100289632235!2d74.2897267!3d18.788791099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc329e3fa88c64f%3A0xfe8cb836779d7ea2!2sCKI%20RUBBER%20TECHNOLOGIES%20(I)%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1707124188765!5m2!1sen!2sin"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Location Map"
                    height="200"
                    className="ratio ratio-21x9"
                  ></iframe>
                </div>
              </div>
            </div>
            {/* <div className="footer-social-links">
              <svg
                className="footer-social-amoeba-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 236 54"
              >
                <path
                  className="footer-social-amoeba-path"
                  d="M223.06,43.32c-.77-7.2,1.87-28.47-20-32.53C187.78,8,180.41,18,178.32,20.7s-5.63,10.1-4.07,16.7-.13,15.23-4.06,15.91-8.75-2.9-6.89-7S167.41,36,167.15,33a18.93,18.93,0,0,0-2.64-8.53c-3.44-5.5-8-11.19-19.12-11.19a21.64,21.64,0,0,0-18.31,9.18c-2.08,2.7-5.66,9.6-4.07,16.69s.64,14.32-6.11,13.9S108.35,46.5,112,36.54s-1.89-21.24-4-23.94S96.34,0,85.23,0,57.46,8.84,56.49,24.56s6.92,20.79,7,24.59c.07,2.75-6.43,4.16-12.92,2.38s-4-10.75-3.46-12.38c1.85-6.6-2-14-4.08-16.69a21.62,21.62,0,0,0-18.3-9.18C13.62,13.28,9.06,19,5.62,24.47A18.81,18.81,0,0,0,3,33a21.85,21.85,0,0,0,1.58,9.08,16.58,16.58,0,0,1,1.06,5A6.75,6.75,0,0,1,0,54H236C235.47,54,223.83,50.52,223.06,43.32Z"
                ></path>
              </svg>
              <a
                className="footer-social-link linkedin"
                href="#"
                target="_blank"
              >
                <span className="hidden-link-text">Facebook</span>
                <FaFacebook />
              </a>
              <a
                className="footer-social-link twitter"
                href="#"
                target="_blank"
              >
                <span className="hidden-link-text">Twitter</span>
                <FaTwitter />
              </a>
              <a
                className="footer-social-link youtube"
                href="#"
                target="_blank"
              >
                <span className="hidden-link-text">Youtube</span>
                <FaYoutube />
              </a>
              <a className="footer-social-link github" href="#" target="_blank">
                <span className="hidden-link-text">Github</span>
                <FaGithub />
              </a>
            </div> */}
          </div>
          <div className="footer-copyright">
            <div className="footer-copyright-wrapper">
              <p className="footer-copyright-text">
                <a className="footer-copyright-link  " href="#" target="_self">
                  ©2024. | Designed By: www.theinnovativesolutions.in
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
