import React from "react";

const Compounding = () => {
  return (
    <>
      {/* <div className="container">
        <h2>In-House Compounding Facility</h2>
        <hr />
        <p>
          Explore the capabilities of our in-house compounding facility, where
          expertise, customization, and innovation converge to create rubber
          compounds that set industry standards.
        </p>

        <table className="table table-striped border">
          <tbody>
            <tr>
              <td>Aspect</td>
              <td>Description</td>
            </tr>
            <tr>
              <td>Mixing Technology</td>
              <td>
                Advanced technology for precise blending of raw rubb er
                materials and additives.
              </td>
            </tr>
            <tr>
              <td>Raw Material Expertise</td>
              <td>
                Staffed with professionals experienced in handling diverse raw
                rubber materials.
              </td>
            </tr>
            <tr>
              <td>Customization Capabilities</td>
              <td>
                Flexibility to customize compound formulations based on client
                requirements.
              </td>
            </tr>
            <tr>
              <td>Quality Control Measures</td>
              <td>
                Rigorous checks at every stage to ensure compounds meet or
                exceed industry standards.
              </td>
            </tr>
            <tr>
              <td>Material Traceability</td>
              <td>
                Implements a robust system for material traceability, allowing
                clients to trace the origin and processing history of each batch
                of rubber compounds.
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </>
  );
};

export default Compounding;
