import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/home/Home'
import About from './pages/about/About'
import Contact from './pages/contact/Contact'
import Gallery from './pages/gallery/Gallery'
import Navbar from './components/navbar/Navbar'
import Footer from './components/footer/Footer'
import Moulding from './pages/moulding/Moulding'
import Infrastructure from './pages/infrastructure/Infrastructure'
import ScrollToTop from './ScrollToTop'
import Compounding from './pages/compounding/Compounding'
import { ToastContainer } from 'react-toastify'
import SuperadminLogin from './pages/SuperAdmin/SuperAdminLogin'
import HomeContainer from './containers/HomeContainer'
import SuperAdminContainer from './containers/SuperAdminContainer'
import ContactEntries from './pages/SuperAdmin/ContactEntries'

const App = () => {
  return (
    <>
      <ScrollToTop />
      <ToastContainer />


      <Routes>

        <Route path='/' element={<HomeContainer />}>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/gallery' element={<Gallery />} />
          <Route exact path='/moulding' element={<Moulding />} />
          <Route exact path='/compounding' element={<Compounding />} />
          <Route exact path='/infrastructure' element={<Infrastructure />} />
        </Route>

        <Route path='/superadmin' element={<SuperadminLogin />} />
        <Route path='/superadmin' element={<SuperAdminContainer />}>
          <Route path='contactentries' element={<ContactEntries />} />
        </Route>

      </Routes >
    </>
  )
}

export default App