import superAdminReducer from "./SuperadminReducers";




const storeReducer = {

    superadmin: superAdminReducer,

};

export default storeReducer;
