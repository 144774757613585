// import React, { useEffect, useState } from "react";
// import "./gallery.css";
// import { IoIosClose } from "react-icons/io";
// import { FaChevronLeft } from "react-icons/fa";
// import { FaChevronRight } from "react-icons/fa";

// const images = [
//   { id: "1", imageName: "a (1).jpeg", tag: "social" },
//   { id: "2", imageName: "a (1).jpg", tag: "cultural" },
//   { id: "3", imageName: "a (2).jpeg", tag: "celebration" },
//   { id: "4", imageName: "a (2).jpg", tag: "social" },
//   { id: "5", imageName: "a (3).jpeg", tag: "cultural" },
//   { id: "6", imageName: "a (3).jpg", tag: "social" },
//   { id: "7", imageName: "a (4).jpeg", tag: "cultural" },
//   { id: "8", imageName: "a (4).jpg", tag: "social" },
//   { id: "9", imageName: "a (5).jpeg", tag: "social" },
//   { id: "10", imageName: "a (5).jpg", tag: "celebration" },
// ];

// const Gallery = () => {
//   const [tag, setTag] = useState("all");
//   const [filteredImages, setFilteredImages] = useState([]);
//   const [model, setModel] = useState(false);
//   const [tempimgSrc, setTempImgSrc] = useState("");
//   const [slideNumber, setSlideNumber] = useState(0);

//   useEffect(() => {
//     tag === "all"
//       ? setFilteredImages(images)
//       : setFilteredImages(images.filter((image) => image.tag === tag));
//   }, [tag]);

//   const getImg = (imageName) => {
//     setTempImgSrc(imageName);
//     setModel(true);
//   };

//   //open modal
//   const handleOpenModal = (index) => {
//     setSlideNumber(index);
//     setModel(true);
//   };

//   // close modal
//   const handleCloseModal = (index) => {
//     setModel(false);
//   };

//   //left slide
//   const preSlide = (index) => {
//     slideNumber === 0
//       ? setSlideNumber(images.length - 1)
//       : setSlideNumber(slideNumber - 1);
//   };

//   //right slide
//   const nextSlide = (index) => {
//     slideNumber + 1 === images.length
//       ? setSlideNumber(0)
//       : setSlideNumber(slideNumber + 1);
//   };

//   return (
//     <>
//       <div className="container w-75 mt-3 text-center ">
//         <h3>Gallery</h3>
//         <hr />
//       </div>

//       {/* LIGHT BOX GALLERY */}
//       <div className={model ? "model open" : "model"}>
//         <div className="sliderWrap">
//           <IoIosClose className="btnclose" onClick={handleCloseModal} />
//           <FaChevronLeft className="btnleft" onClick={preSlide} />
//           <FaChevronRight className="btnright" onClick={nextSlide} />
//           <div className="fullScreenImage">
//             <img src={filteredImages[slideNumber]} alt="" />
//           </div>
//         </div>
//         <img src={tempimgSrc} alt="" />
//         {/* <i className="fa fa-close close" onClick={() => setModel(false)}></i> */}
//       </div>

//       <div className="container justify-content-center" handleSetTag={setTag}>
//         <div className="gallery-btn">
//           <TagButton name="social" handleSetTag={setTag} />
//           <TagButton name="cultural" handleSetTag={setTag} />
//           <TagButton name="celebration" handleSetTag={setTag} />
//           <TagButton name="all" handleSetTag={setTag} />
//         </div>

//         <div className="gallery-imgs">
//           {filteredImages.map((image) => (
//             <div key={image.id} className="p-2">
//               <img
//                 className="gallery"
//                 src={`/images/${image.imageName}`}
//                 alt=""
//                 onClick={() => getImg(`/${image.imageName}`)}
//               />
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// const TagButton = ({ name, handleSetTag }) => {
//   return (
//     <>
//       <button
//         className="btn btn-dark rounded-3 gallery-tag"
//         onClick={() => handleSetTag(name)}
//       >
//         {name.toUpperCase()}
//       </button>
//     </>
//   );
// };

// export default Gallery;

import React, { useState, useEffect } from "react";
import {
  AiFillCloseCircle,
  AiFillRightCircle,
  AiFillLeftCircle,
} from "react-icons/ai";
import { NavLink } from "react-router-dom";
import "./gallery.css";

const Gallery = () => {
  const galleryImages = [
    { id: "1", img: "/images/a (1).jpeg", tag: "latest" },
    { id: "2", img: "/images/a (1).jpg", tag: "latest" },
    { id: "8", img: "/images/a (4).jpg", tag: "team" },
    { id: "6", img: "/images/a (3).jpg", tag: "function" },
    { id: "7", img: "/images/a (4).jpeg", tag: "function" },
    { id: "5", img: "/images/a (3).jpeg", tag: "function" },
    { id: "10", img: "/images/a (5).jpg", tag: "all" },
    { id: "3", img: "/images/a (2).jpeg", tag: "awards" },
    { id: "4", img: "/images/a (2).jpg", tag: "awards" },
  ];

  const [tag, setTag] = useState("all");
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    tag === "all"
      ? setFilteredImages(galleryImages)
      : setFilteredImages(galleryImages.filter((image) => image.tag === tag));
  }, [tag]);

  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  //open modal
  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  // close modal
  const handleCloseModal = (index) => {
    setOpenModal(false);
  };

  //left slide
  const preSlide = (index) => {
    slideNumber === 0
      ? setSlideNumber(galleryImages.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  //right slide
  const nextSlide = (index) => {
    slideNumber + 1 === galleryImages.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };
  return (
    <div>
      {openModal && (
        <div className="sliderWrap">
          <AiFillCloseCircle className="btnclose" onClick={handleCloseModal} />
          <AiFillLeftCircle className="btnleft" onClick={preSlide} />
          <AiFillRightCircle className="btnright" onClick={nextSlide} />
          <div className="fullScreenImage">
            <img src={filteredImages[slideNumber].img} alt="" />
          </div>
        </div>
      )}

      <div className="gallery-btn text-center mt-3">
        <TagButton name="latest" handleSetTag={setTag} />
        <TagButton name="awards" handleSetTag={setTag} />
        <TagButton name="function" handleSetTag={setTag} />
        <TagButton name="team" handleSetTag={setTag} />
        <TagButton name="all" handleSetTag={setTag} />
      </div>
      <div className="galleryWrap mt-5">
        {filteredImages.map((slide, index) => {
          return (
            <div
              className="single"
              key={index}
              onClick={() => handleOpenModal(index)}
            >
              <img src={slide.img} alt="..." className="border border-2" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
const TagButton = ({ name, handleSetTag }) => {
  return (
    <>
      <button
        className="btn btn-dark rounded-0 gallery-tag m-1"
        onClick={() => handleSetTag(name)}
      >
        {name.toUpperCase()}
      </button>
    </>
  );
};
export default Gallery;
