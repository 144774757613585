import React from "react";
import "./slider.css";

const Slider = () => {
  return (
    <div>
      <div
        id="carouselExampleCaptions"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="1500">
            <img src="/images/a (5).jpg" class="d-block w-100" alt="..." />
            <div class="carousel-caption align-self-center  text-white d-none d-md-block">
              <div>
                <h1 className="">CKI Rubber Technologies (I) Private Ltd.</h1>
                <p>
                  Founded 2023, CKI Rubber Technologies (I) Pvt Ltd was born out
                  of a visionary pursuit to redefine the landscape of the rubber
                  industry. Our journey began with a simple yet profound goal:
                  to create a company that not only delivers exceptional
                  products but also pioneers positive change.{" "}
                </p>
                {/* <div className="btn btn-primary rounded-5">Read More</div> */}
              </div>
            </div>
          </div>
          <div class="carousel-item" data-bs-interval="1500">
            <img src="/images/a (1).jpeg" class="d-block w-100" alt="..." />
            <div class="carousel-caption align-self-center   text-white d-none d-md-block">
              <div>
                <h1 className="my-5">
                  CKI Rubber Technologies (I) Private Ltd.
                </h1>
                <p>
                  Founded 2023, CKI Rubber Technologies (I) Pvt Ltd was born out
                  of a visionary pursuit to redefine the landscape of the rubber
                  industry. Our journey began with a simple yet profound goal:
                  to create a company that not only delivers exceptional
                  products but also pioneers positive change.
                </p>
              </div>
            </div>
          </div>
          <div class="carousel-item" data-bs-interval="1500">
            <img src="/images/a (1).jpg" class="d-block w-100" alt="..." />
            <div class="carousel-caption align-self-center   text-white d-none d-md-block">
              <div>
                <h1 className="my-5">
                  CKI Rubber Technologies (I) Private Ltd.
                </h1>
                <p>
                  Founded 2023, CKI Rubber Technologies (I) Pvt Ltd was born out
                  of a visionary pursuit to redefine the landscape of the rubber
                  industry. Our journey began with a simple yet profound goal:
                  to create a company that not only delivers exceptional
                  products but also pioneers positive change.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Slider;
