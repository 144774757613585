// superAdminReducer.js
import { toast } from "react-toastify";
import { removeSuperAdminToken } from "../../common";
import { superAdminTokenName } from "../../config";

const getSuperAdminFromLocalStorage = () => {
    try {
        const superadmin = JSON.parse(sessionStorage.getItem(superAdminTokenName));
        if (superadmin && superadmin.authToken && superadmin.loggedInTime) {
            return superadmin;
        } else {
            removeSuperAdminToken();
            return null;
        }
    } catch (error) {
        removeSuperAdminToken();
        return null;
    }
};

const getInitial = () => {
    return getSuperAdminFromLocalStorage();
};

const superAdminReducer = (state = getInitial(), action) => {
    switch (action.type) {
        case "LOGIN_SUPER_ADMIN":
            state = {};
            const time = action.payload.loggedInTime;
            if (time) {
                state.loggedInTime = time;
            } else {
                state.loggedInTime = Date.now();
            }
            state.authToken = action.payload.authToken;
            sessionStorage.setItem(superAdminTokenName, JSON.stringify(state));
            return state;
        case "LOGOUT_SUPER_ADMIN":
            removeSuperAdminToken();
            toast.info("Logout successful", { toastId: "LogoutReplacable" });

            return null;
        case "UPDATE_SUPER_ADMIN_DASHBOARD":
            if (state) {
                state = {
                    ...state,
                    dashboard: {
                        ...state.dashboard,
                        ...action.payload,
                    },
                };
            }
            return state;
        case "CLEAR_SUPER_ADMIN_DASHBOARD":
            state.dashboard = null;
            return state;
        default:
            return state;
    }
};

export default superAdminReducer;