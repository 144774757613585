import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { server } from "../../common";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuperAdmin } from "../../redux/actions/SuperadminActions";
const swalalert = withReactContent(Swal);

const ContactEntries = ({ role }) => {
    const superadmin = useSelector((store) => store.superadmin);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        getData();
    }, []);

    // Get
    const getData = () => {
        server
            .get("/contact/getall", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: superadmin.authToken,
                },
            })
            .then(function (response) {
                console.log("api response", response.data);
                if (response.status === 200 || response.status === 201) {
                    setData(response.data);
                }
            })
            .catch(function (error) {
                if (error instanceof AxiosError && error.response?.data?.message)
                    toast.error(error.response.data.message);
                else if (error.response?.data?.error) {
                    toast.error(error.response.data.error);
                } else toast.error("Failed to connect to server");
            });
    };

    // Delete gallery
    const deleteInternShip = (data) => {
        swalalert
            .fire({
                title: "Delete Confirmation!",
                text: `Are You Sure That You Want To Delete ${data.link} ?`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
                buttonsStyling: true,
            })
            .then(function (swalObject) {
                if (swalObject.isConfirmed) {
                    server
                        .delete(`/contact/delete/${data._id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                // "auth-Token": user.authToken,
                            },
                        })
                        .then(function (response) {
                            console.log("api response", response.data);
                            if (response.status === 200 || response.status === 201) {
                                swalalert.fire(
                                    "Deleted!",
                                    "Contact entry has been deleted.",
                                    "success"
                                );
                                getData();
                            }
                        })
                        .catch((error) => {
                            toast.error(error.response.data.message);
                        });
                } else {
                    swalalert.fire(
                        "Cancelled",
                        "Your imaginary file is safe :)",
                        "error"
                    );
                }
                //success method
            });
    };

    // // search filter wise
    // const FilterData = (event) => {
    //     const selectedValue = event.target.value;
    //     server
    //         .get(`/contact/admfilter?filterByDate=${selectedValue}`, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: user.authToken,
    //             },
    //         })
    //         .then(function (response) {
    //             console.log("api response", response.data);
    //             if (response.status === 200 || response.status === 201) {
    //                 setData(response.data);
    //             }
    //         })
    //         .catch(function (error) {
    //             if (error instanceof AxiosError && error.response?.data?.message)
    //                 toast.error(error.response.data.message);
    //             else if (error.response?.data?.error) {
    //                 toast.error(error.response.data.error);
    //             } else toast.error("Failed to connect to server");
    //         });
    // };

    return (
        <>
            <div className="bg-info p-3">
                <div className="row">
                    <div className="col-md-11 text-center text-white">
                        <h4 className="">Welcome To CKI Rubber Technologies (I) Private Ltd. Super Admin Panel
                        </h4>
                    </div>
                    <div className="col-md-1 align-self-center">
                        <button className="btn btn-danger" onClick={() => dispatch(logoutSuperAdmin())}>Logout</button>
                    </div>
                </div>
            </div >
            <div className="container">
                <h2 className="text-center py-4">Contact Entries</h2>
                {/* <div className="row my-3">
                    <div className="col-md-4">
                        <input
                            type="search"
                            name=""
                            className="form-control"
                            placeholder="Search"
                            id=""
                        />
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <select
                            className="form-control"
                            name=""
                            id=""
                            onChange={(e) => FilterData(e)} // Pass the event to FilterData
                        >
                            <option value="2days" defaultValue>
                                Last 2 days
                            </option>
                            <option value="month" defaultValue>
                                This Month
                            </option>
                            <option value="3months">Last 3 Months</option>
                            <option value="6months">Last 6 Months</option>
                        </select>
                    </div>
                </div> */}
                <div style={{ overflowX: "scroll" }}>
                    <table className="table table-striped border">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Subject</th>
                                <th scope="col">Message</th>
                                <th scope="col">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => {
                                return (
                                    <>
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.fullname}</td>
                                            <td>{item.email}</td>
                                            <td>{item.mobile}</td>
                                            <td>{item.message}</td>
                                            <td>
                                                <div className="btn btn-danger">
                                                    <AiFillDelete onClick={() => deleteInternShip(item)} />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ContactEntries;
