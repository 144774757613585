import { TextField, Button } from "@mui/material";
import React from "react";
import "./contact.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { server } from "../../common";

const initialValues = {
  fullname: "",
  email: "",
  mobile: "",
  message: "",
};

const Contact = () => {
  const navigate = useNavigate();

  const { values, touched, handleSubmit, handleChange, resetForm, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: Yup.object({
        fullname: Yup.string()
          .min(2, "Enter Full Name")
          .required("Please Enter Your Name"),
        email: Yup.string()
          .email("Enter a valid email")
          .required("Please Enter Your Email"),

        mobile: Yup.string()
          .min(2, "Enter Mobile Number")
          .required("Please Enter Your Mobile Number"),

        message: Yup.string()
          .min(2, "Enter details")
          .required("Please Enter Your Message"),
      }),
      onSubmit: (values) => {
        server
          .post("/contact/add", values, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(function (response) {
            console.log("api response", response.data);
            if (response.status === 200 || response.status === 201) {
              if (response.data) {
                // dispatch(loginUser({ authToken: response.data.token }));
                toast.success("Message successfully");
                navigate("/");
                resetForm();
              }
            }
          })
          .catch(function (error) {
            if (error instanceof AxiosError && error.response?.data?.message)
              toast.error(error.response.data.message);
            else if (error.response?.data?.error) {
              toast.error(error.response.data.error);
            } else {
              toast.error("Failed to connect to server");
              console.log(error.message);
            }
          });
      },
    });

  return (
    <div>
      {" "}
      <div className="contact_container container-width-auto container-xl vh-50">
        <div className="text-center">
          <h3 className=" mt-4">Contact Us</h3>
          <hr />
        </div>
        <div className="row align-items-center m-auto container-fluid">
          <div className="col d-block">
            <div className="map-responsive ">
              <iframe
                className="gmap_iframe border p-2"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3777.2100289632235!2d74.2897267!3d18.788791099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc329e3fa88c64f%3A0xfe8cb836779d7ea2!2sCKI%20RUBBER%20TECHNOLOGIES%20(I)%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1707124188765!5m2!1sen!2sin"
              ></iframe>
            </div>
            {/* <div>
          <span><FcAddressBook/></span>
          <h5>Address : Plot no.338/2, Vrundavan Nagar, Near Shivkrupa Sweets, Nandur Naka, Jatra Hotel Link Road, Adgaon-Shivar, Nashik, MH-422003</h5>

        </div> */}
          </div>
          <div className="col">
            <form onSubmit={handleSubmit}>
              <TextField
                name="fullname"
                margin="normal"
                type="text"
                placeholder="loremipsum"
                variant="outlined"
                label="Full Name"
                value={values.fullname}
                onChange={handleChange}
                // onBlur={handleBlur}
                fullWidth
                required
              ></TextField>
              {errors.fullname && touched.fullname ? (
                <p className="text-danger">{errors.fullname}</p>
              ) : null}

              <TextField
                name="email"
                margin="normal"
                type="email"
                placeholder="loremipsum@abc.com"
                variant="outlined"
                label="Email"
                value={values.email}
                onChange={handleChange}
                // onBlur={handleBlur}
                fullWidth
                required
              ></TextField>
              {errors.email && touched.email ? (
                <p className="text-danger">{errors.email}</p>
              ) : null}

              <TextField
                name="mobile"
                margin="normal"
                type="number"
                placeholder="+91 6666678321"
                variant="outlined"
                label="Contact No"
                value={values.mobile}
                onChange={handleChange}
                // onBlur={handleBlur}
                fullWidth
                required
              ></TextField>
              {errors.mobile && touched.mobile ? (
                <p className="text-danger">{errors.mobile}</p>
              ) : null}

              <TextField
                name="message"
                margin="normal"
                type="text"
                placeholder="Your meesage"
                variant="outlined"
                label="Message"
                minRows={4}
                multiline
                value={values.message}
                onChange={handleChange}
                // onBlur={handleBlur}
                fullWidth
                required
              ></TextField>
              {errors.message && touched.message ? (
                <p className="text-danger">{errors.message}</p>
              ) : null}
              <Button
                type="submit"
                className="px-3 my-1  text-white submit"
                variant="contained"
              >
                Send Message
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
