import React, { useState } from "react";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const About = () => {
  const [selectedPerson, setSelectedPerson] = useState(null);

  const teamMembers = [
    {
      id: 1,
      name: "Person 1",
      role: "Chairman",
      image:
        "https://static.vecteezy.com/system/resources/thumbnails/034/598/790/small/ai-generative-happy-business-man-in-a-suit-white-background-photo.jpg",
      message:
        "Welcome1 to CKI Rubber Technologies (I) Pvt Ltd, the pinnacle of innovation, quality, and sustainability in the rubber industry. We are thrilled to connect with you and share the passion that propels our journey. Since our inception, our vision has been crystal clear – to redefine industry benchmarks and consistently deliver rubber products that not only meet but exceed expectations. It&#39;s a commitment to excellence that resonates in every meticulously crafted item, reflecting our unwavering dedication to precision and quality.",
    },
    {
      id: 2,
      name: "Person 2",
      role: "Chairman",
      image:
        "https://static.vecteezy.com/system/resources/thumbnails/034/598/790/small/ai-generative-happy-business-man-in-a-suit-white-background-photo.jpg",
      message:
        "Welcome2 to CKI Rubber Technologies (I) Pvt Ltd, the pinnacle of innovation, quality, and sustainability in the rubber industry. We are thrilled to connect with you and share the passion that propels our journey. Since our inception, our vision has been crystal clear – to redefine industry benchmarks and consistently deliver rubber products that not only meet but exceed expectations. It&#39;s a commitment to excellence that resonates in every meticulously crafted item, reflecting our unwavering dedication to precision and quality.",
    },
    {
      id: 3,
      name: "Person 3",
      role: "Chairman",
      image:
        "https://static.vecteezy.com/system/resources/thumbnails/034/598/790/small/ai-generative-happy-business-man-in-a-suit-white-background-photo.jpg",
      message:
        "Welcome3 to CKI Rubber Technologies (I) Pvt Ltd, the pinnacle of innovation, quality, and sustainability in the rubber industry. We are thrilled to connect with you and share the passion that propels our journey. Since our inception, our vision has been crystal clear – to redefine industry benchmarks and consistently deliver rubber products that not only meet but exceed expectations. It&#39;s a commitment to excellence that resonates in every meticulously crafted item, reflecting our unwavering dedication to precision and quality.",
    },
    {
      id: 4,
      name: "Person 4",
      role: "Chairman",
      image:
        "https://static.vecteezy.com/system/resources/thumbnails/034/598/790/small/ai-generative-happy-business-man-in-a-suit-white-background-photo.jpg",
      message:
        "Welcome4 to CKI Rubber Technologies (I) Pvt Ltd, the pinnacle of innovation, quality, and sustainability in the rubber industry. We are thrilled to connect with you and share the passion that propels our journey. Since our inception, our vision has been crystal clear – to redefine industry benchmarks and consistently deliver rubber products that not only meet but exceed expectations. It&#39;s a commitment to excellence that resonates in every meticulously crafted item, reflecting our unwavering dedication to precision and quality.",
    },
    // Add more team members as needed
  ];

  const handlePersonClick = (personId) => {
    setSelectedPerson(personId === selectedPerson ? null : personId);
  };

  const handlePersonHover = (personId) => {
    setSelectedPerson(personId);
  };

  return (
    <>
      {/* <div className="container p-4">
        <h2>Our Team</h2>
        <hr />
        <Swiper
          modules={[Navigation, A11y]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
          <div className="row align-content-center">
            <SwiperSlide>
              <div className="col py-3 ">
                <div className="card card-style shadow ">
                  <img
                    src="https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1448711260.1707177600&semt=ais"
                    alt=""
                    className="card-img-top "
                  />

                  <div className="p-2">
                    <h5 className=" my-2  fw-bold p-2">Team Name</h5>
                    <p>Job Position</p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Minima, repellat.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="col py-3 ">
                <div className="card card-style shadow ">
                  <img
                    src="https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1448711260.1707177600&semt=ais"
                    alt=""
                    className="card-img-top "
                  />

                  <div className="p-2">
                    <h5 className=" my-2  fw-bold p-2">Team Name</h5>
                    <p>Job Position</p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Minima, repellat.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="col py-3 ">
                <div className="card card-style shadow ">
                  <img
                    src="https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1448711260.1707177600&semt=ais"
                    alt=""
                    className="card-img-top "
                  />

                  <div className="p-2">
                    <h5 className=" my-2  fw-bold p-2">Team Name</h5>
                    <p>Job Position</p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Minima, repellat.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="col py-3 ">
                <div className="card card-style shadow ">
                  <img
                    src="https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1448711260.1707177600&semt=ais"
                    alt=""
                    className="card-img-top "
                  />

                  <div className="p-2">
                    <h5 className=" my-2  fw-bold p-2">Team Name</h5>
                    <p>Job Position</p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Minima, repellat.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="col py-3 ">
                <div className="card card-style shadow ">
                  <img
                    src="https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1448711260.1707177600&semt=ais"
                    alt=""
                    className="card-img-top "
                  />

                  <div className="p-2">
                    <h5 className=" my-2  fw-bold p-2">Team Name</h5>
                    <p>Job Position</p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Minima, repellat.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </div>
        </Swiper>
      </div> */}

      {/* <div className="container p-4">
        <h2>About Us</h2>
        <hr />
        <div className="row">
          <div className="col-md-6" style={{ textAlign: "justify" }}>
            Welcome to CKI Rubber Technologies (I) Pvt Ltd, the pinnacle of
            innovation, quality, and sustainability in the rubber industry. We
            are thrilled to connect with you and share the passion that propels
            our journey. Since our inception, our vision has been crystal clear
            – to redefine industry benchmarks and consistently deliver rubber
            products that not only meet but exceed expectations. It&#39;s a
            commitment to excellence that resonates in every meticulously
            crafted item, reflecting our unwavering dedication to precision and
            quality. Innovation is the heartbeat of our company. We thrive on
            the pulse of progress, constantly evolving to anticipate and address
            your ever-changing needs. Our commitment to staying at the forefront
            of technological advancements ensures that our solutions not only
            meet the present but anticipate the future. To our valued customers,
            trusted partners, and dedicated team members, your unwavering
            support is the cornerstone of our success.
          </div>
          <div className="col-md-6 text-center">
            <img src="/about.png" alt="" />
          </div>
        </div>
      </div> */}

      <div className="container p-4">
        <h2>Founder's Message</h2>
        <hr />
        <div className="row">
          <div className="col-md-6" style={{ textAlign: "justify" }}>
            <b> Dear Esteemed Visitors,</b> <br />
            Welcome to CKI Rubber Technologies (I) Pvt Ltd, the pinnacle of
            innovation, quality, and sustainability in the rubber industry. We
            are thrilled to connect with you and share the passion that propels
            our journey. Since our inception, our vision has been crystal clear
            – to redefine industry benchmarks and consistently deliver rubber
            products that not only meet but exceed expectations. It&#39;s a
            commitment to excellence that resonates in every meticulously
            crafted item, reflecting our unwavering dedication to precision and
            quality. Innovation is the heartbeat of our company. We thrive on
            the pulse of progress, constantly evolving to anticipate and address
            your ever-changing needs. Our commitment to staying at the forefront
            of technological advancements ensures that our solutions not only
            meet the present but anticipate the future. To our valued customers,
            trusted partners, and dedicated team members, your unwavering
            support is the cornerstone of our success. Together, we form a
            dynamic community dedicated to progress, innovation, and positive
            impact. As we navigate the exciting road ahead, we pledge to uphold
            the highest standards of integrity, foster a culture of continuous
            improvement, and be at the forefront of industry innovation. Thank
            you for being an integral part of the CKI narrative. We invite you
            to explore the possibilities with us and witness the transformative
            power of rubber innovation.
            <br />
            <b> Warm Regards,</b>
            <br /> <b>Director (CKI Technologies (I) Pvt Ltd)</b>
          </div>
          <div className="col-md-6 text-center align-self-center">
            <img src="/images/about.png" alt="" className="img-fluid" />
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <h4>Vision</h4>
            <p>
              CKI Rubber Technologies (I) Pvt Ltd envisions being the forefront
              of rubber manufacturing, committed to delivering high-quality
              products through constant innovation. Our values propel us to
              redefine industry standards, creating a lasting impact through
              eco-friendly practices and unwavering commitment to excellence.
            </p>
            <h4>Mission</h4>
            <p>
              Our mission is to deliver high-quality products, prioritizing
              innovation and sustainability. Striving for excellence, we aim to
              exceed customer expectations, upholding the highest industry
              standards. Through continuous improvement, we aspire to be a
              trusted partner, consistently providing exceptional value to our
              customers.
            </p>
          </div>
          <div className="col-md-6 align-self-center">
            <img
              src="https://assets-global.website-files.com/618ec2e36c7ec23e185f0a7e/626bea63cf5d1493d816d38d_Blog%2023_1.png"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      {/* 
      <div className="container  text-center">
        <div className="row mt-5">
          <h2 className="my-3">Founder's messages</h2>
          <hr />
          {teamMembers.map((person) => (
            <div
              key={person.id}
              className="col"
              onClick={() => handlePersonClick(person.id)}
              onMouseEnter={() => handlePersonHover(person.id)}
              onMouseLeave={() => handlePersonHover(null)}
            >
              <div
                className={`shadow p-2 ${
                  person.id === selectedPerson ? "selected" : ""
                }`}
              >
                <img src={person.image} alt="" className="rounded-circle" />
                <h5>{person.name}</h5>
                <p>{person.role}</p>
              </div>
            </div>
          ))}
        </div>
        {selectedPerson !== null && (
          <div className="row mt-3">
            <div className="col">
              <p>
                {
                  teamMembers.find((person) => person.id === selectedPerson)
                    ?.message
                }
              </p>
            </div>
          </div>
        )}
      </div> */}

      {/* <div className="container mt-5">
        <h2 className="">Founder’s Message</h2>
        <hr />
        <p>
          Dear Esteemed Visitors, <br />
          Welcome to CKI Rubber Technologies (I) Pvt Ltd, the pinnacle of
          innovation, quality, and sustainability in the rubber industry. We are
          thrilled to connect with you and share the passion that propels our
          journey. Since our inception, our vision has been crystal clear – to
          redefine industry benchmarks and consistently deliver rubber products
          that not only meet but exceed expectations. It&#39;s a commitment to
          excellence that resonates in every meticulously crafted item,
          reflecting our unwavering dedication to precision and quality.
          Innovation is the heartbeat of our company. We thrive on the pulse of
          progress, constantly evolving to anticipate and address your
          ever-changing needs. Our commitment to staying at the forefront of
          technological advancements ensures that our solutions not only meet
          the present but anticipate the future. To our valued customers,
          trusted partners, and dedicated team members, your unwavering support
          is the cornerstone of our success. Together, we form a dynamic
          community dedicated to progress, innovation, and positive impact.
        </p>
      </div> */}

      <div className="container mt-5">
        <div className="row ">
          <h2>Core Values</h2>
          <hr />

          <div className="row">
            <div className="col-md-6" style={{ textAlign: "justify" }}>
              <p>
                <b> Quality Excellence:</b> Commitment to delivering rubber
                products of the highest quality, meeting or exceeding industry
                standards.
              </p>
              <p>
                <b>Innovation:</b> Embrace continuous improvement and foster a
                culture of innovation to stay at the forefront of technological
                advancements in rubber manufacturing.
              </p>
              <p>
                <b>Customer-Centric Approach:</b> Focus on understanding and
                exceeding customer expectations, building long-term
                relationships based on trust and satisfaction.
              </p>
              <p>
                <b>Integrity:</b> Uphold the highest ethical standards in all
                business practices, ensuring transparency, honesty, and
                accountability.
              </p>
              <p>
                <b>Continuous Improvement:</b> Foster a culture of continuous
                learning and improvement, seeking efficiencies and advancements
                in processes and products.
              </p>
              <p>
                <b>Collaboration:</b> We believe in the power of collaboration,
                both internally and with external partners. By fostering
                teamwork and open communication, we leverage collective
                expertise to achieve common goals and drive shared success.
              </p>
            </div>
            <div className="col-md-6 align-self-center">
              <img src="/images/ss.png" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
