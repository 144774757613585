import axios from "axios";
import { BACKEND_SERVER_URL, superAdminTokenName, } from "./config";

export const server = axios.create({
    baseURL: BACKEND_SERVER_URL,
    proxy: BACKEND_SERVER_URL,
});

export const getSuperAdminToken = () => {
    return sessionStorage.setItem(superAdminTokenName);
};

export const removeSuperAdminToken = () => {
    return sessionStorage.removeItem(superAdminTokenName);
};

