import React, { useEffect } from "react";
import Aos from "aos";

const Moulding = () => {
  useEffect(() => {
    const initAos = () => {
      Aos.init({ duration: 2000 });
    };

    initAos();
  }, []);
  return (
    <>
      <div className="container p-4">
        <div>
          {/* <h2 className="text-uppercase">PRODUCTS AND SERVICES </h2> <hr /> */}
          <p className="text-muted " style={{ textAlign: "justify" }}>
            Welcome to the CKI Rubber Technologies (I) Pvt Ltd, Product and
            Services Hub, where innovation meets excellence. Our diverse range
            of cutting-edge rubber solutions is designed to cater to the unique
            needs of various industries. From automotive to healthcare,
            construction to electronics, we take pride in offering unparalleled
            quality and customization capabilities. Explore our comprehensive
            suite of products, each crafted with precision and guided by our
            commitment to sustainability, innovation, and customer satisfaction.
            At CKI Rubber Technologies (I) Pvt Ltd, we don&#39;t just provide
            products; we deliver solutions that redefine industry standards.
            Discover the power of rubber innovation with us.
          </p>
        </div>

        <div className=" my-5">
          <h2 className="text-uppercase ">
            Building Excellence
            {/* : In-House Rubber Compounding Solution */}
          </h2>
          <hr />

          <p>
            <b> Feasibility Analysis:</b> Conduct a comprehensive feasibility
            study to assess the economic viability and potential benefits of
            establishing an in-house compounding facility.
          </p>
          <p>
            <b>Define Objectives and Scope:</b> Clearly define the objectives
            and scope of the compounding facility, including the types of rubber
            compounds to be produced, target industries, and customization
            capabilities.
          </p>
          <p>
            <b>Regulatory Compliance:</b> Understand and comply with regulatory
            requirements related to rubber compounding, ensuring adherence to
            industry standards and environmental regulations.
          </p>
          <p>
            <b>Facility Design and Layout:</b> Collaborate with architects and
            engineers to design a layout that optimizes workflow, segregates
            different stages of compounding, and integrates safety features.
          </p>
          <p>
            <b>Acquire Advanced Equipment:</b> Invest in state-of-the-art mixing
            equipment, such as internal mixers, open mills, and other
            specialized machinery based on the intended applications and
            production volume.
          </p>
          <p>
            <b>Raw Material Procurement:</b> Establish reliable supply chains
            for raw rubber materials, ensuring high- quality and consistent
            supply. Explore sustainable sourcing options to align with
            environmental goals.
          </p>
          <p>
            <b>Staffing and Training:</b> Hire experienced chemists, material
            scientists, and skilled operators. Provide comprehensive training
            programs to ensure the team is well- versed in compounding processes
            and safety protocols.
          </p>
          <p>
            <b>Quality Control Protocols:</b> Develop and implement stringent
            quality control protocols, including material testing, viscosity
            checks, and comprehensive testing of finished compounds to meet
            industry standards.
          </p>
          <p>
            <b>Efficiency Optimization:</b> Implement efficient production
            processes, automation where applicable, and continuous improvement
            initiatives to optimize workflow and reduce lead times.
          </p>
          <p>
            <b>Advanced Testing Facilities:</b> Equip the facility with advanced
            testing equipment to conduct thorough quality assurance, including
            hardness testers, rheometers, and spectrometers.
          </p>
        </div>

        {/* <div className="container p-3 mt-5">
          <h2 className="text-uppercase py-3">
            In-House Rubber Moulding Facility
          </h2>
          <p className="text-muted" style={{ textAlign: "justify" }}>
            Our in-house rubber moulding facility stands as a beacon of
            innovation and precision, equipped with state-of-the-art technology
            to meet the dynamic needs of our clients. Our advanced moulding
            processes, including compression moulding and injection moulding,
            enable us to produce high-quality rubber components with exceptional
            accuracy and consistency. What sets our facility apart is the
            seamless integration of customization capabilities, allowing clients
            to tailor moulds according to their specific requirements. Beyond
            mere moulding, our facility excels in material expertise, guiding
            clients in the selection of the most suitable elastomers for their
            applications. Rigorous quality control measures are ingrained in our
            processes, from in-process inspections to post-moulding checks,
            ensuring that each rubber product meets the highest industry
            standards. We prioritize efficiency in production, utilizing
            automated systems and streamlined workflows to achieve high-volume
            production without compromising on quality. Prototyping and sampling
            services further facilitate client collaboration, allowing for
            evaluation and adjustments before full-scale production.
          </p>
        </div>
        <div className="container p-3 mt-3 ">
          <h2 className="text-uppercase">Raw Materials we process</h2>
          <img src="/d.jpg" alt="" className="img-fluid" />
        </div> */}

        <div className="container p-3 mt-3 ">
          <h2 className="text-uppercase">Raw Materials we process</h2>
          <img src="/images/d.jpg" alt="" className="img-fluid" />
        </div>
      </div>
    </>
  );
};

export default Moulding;
