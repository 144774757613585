import React from "react";
import "./navbar.css";
import { NavLink } from "react-router-dom";
import { CiFacebook, CiInstagram, CiMail, CiTwitter } from "react-icons/ci";
import { FaMailBulk, FaWhatsapp } from "react-icons/fa";

const Navbar = () => {
  return (
    <>
      <div>
        <div className="row my-1">
          <div className="col-md-2 text-center">
            <div className="navbar-brand">
              <NavLink to="/">
                <img
                  src="/images/logo.jpeg"
                  // className="img-fluid"
                  alt=""
                  width={180}
                />
              </NavLink>
            </div>
          </div>
          <div className="col-md-8 align-self-center text-center d-none d-md-block">
            <h4>CKI Rubber Technologies (I) Private Ltd.</h4>
          </div>
          <div className="col-md-2 align-self-center text-center d-none d-md-block">
            <p className="gap-5 fs-4">
              {/* <CiFacebook className="me-2" />
              <CiInstagram className="me-2" />
              <CiTwitter className="me-2" />
              <FaWhatsapp className="me-2" /> */}
            </p>

            <p>
              <CiMail />{" "}
              <a
                href="mailto: enquiry@ckirubber.com"
                className="text-decoration-none text-dark"
              >
                enquiry@ckirubber.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <nav className="navbar text-uppercase navbar-expand-lg bg-white shadow-sm sticky-top">
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link active" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li>

              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Products Info
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/moulding">
                      Rubber Moulding
                    </NavLink>
                    <NavLink className="dropdown-item" to="/compounding">
                      Rubber Compounding & Mixing
                    </NavLink>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item">
                <NavLink className="nav-link" to="/moulding">
                  Products & Services
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/infrastructure">
                  Infrastructure Highlights
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/compounding">
                  Rubber Compounding & Mixing
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/moulding">
                  Rubber Moulding
                </NavLink>
              </li> */}

              <li className="nav-item">
                <NavLink className="nav-link" to="/gallery">
                  Gallery
                </NavLink>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  More
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/infrastructure">
                      Infrastructure Highlights
                    </NavLink>
                  </li>
                </ul>
              </li>
               */}
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
