import React from "react";
import Slider from "../../components/slider/Slider";
import { Carousel } from "react-responsive-carousel";
import "./home.css";
import Testinomials from "../../components/Testinomials";
import Marquee from "react-fast-marquee";
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Slider />

      {/* about section */}
      <div className="container p-4 mt-3">
        <div className="row p-4 shadow ">
          <div
            className="col bg-white position-relative"
            style={{ float: "left" }}
          >
            <div className="overlay-content">
              <h2>Welcome to CKI Rubber Technologies (I) Private LTD.</h2>
              <p style={{ textAlign: "justify" }}>
                Welcome to CKI Rubber Technologies (I) Pvt Ltd, the pinnacle of
                innovation, quality, and sustainability in the rubber industry.
                We are thrilled to connect with you and share the passion that
                propels our journey. Since our inception, our vision has been
                crystal clear – to redefine industry benchmarks and consistently
                deliver rubber products that not only meet but exceed
                expectations. It's a commitment to excellence that resonates in
                every meticulously crafted item, reflecting our unwavering
                dedication to precision and quality. Innovation is the heartbeat
                of our company.
              </p>
            </div>
          </div>
          <div className="col-md-6 main2 text-center align-self-center">
            <img src="/images/a (2).jpeg" alt="" className="img-fluid" />
          </div>
        </div>
      </div>

      {/* Our Rubber Compounding and Mixing */}
      <div className="main text-center p-4 ">
        <h2 className="text-uppercase">Our Services </h2>
        <hr />
        <div className="container">
          <div className="row p-3">
            <div className="col-md-6 my-2 ">
              <div className="card">
                <img
                  src="https://img1.wsimg.com/isteam/ip/ea607c1e-4afe-4532-98df-4b0ad7c2cd64/DSC_3714.jpg/:/cr=t:0%25,l:11.54%25,w:76.92%25,h:57.61%25/rs=w:388,h:194,cg:true,m"
                  alt=""
                  className="card-img-top"
                  height={250}
                />
                <div className="card-body">
                  <h5>Rubber Mixing Mill &amp; Kneader</h5>
                  <p>
                    Discover the heart of our manufacturing expertise – the
                    advanced Rubber Mixing Mill. Fitted with top-notch
                    technology, it skilfully blends raw rubber materials with
                    additives, creating consistent compounds crucial for various
                    uses.
                  </p>
                  <NavLink
                    to="/moulding"
                    className="btn btn-primary rounded-5 my-3"
                  >
                    Read More
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-md-6 my-2 ">
              <div className="card">
                <img
                  src="/images/a (2).jpeg"
                  alt=""
                  className="card-img-top"
                  height={250}
                />
                <div className="card-body">
                  <h5>Vacuum Compression Moulding</h5>
                  <p>
                    Experience precision in rubber shaping with our advanced
                    Vacuum Compression moulding facility. This technology
                    enables us to produce intricate rubber components with
                    unmatched accuracy and detail.
                  </p>
                  <NavLink
                    to="/moulding"
                    className="btn btn-primary rounded-5 my-3"
                  >
                    Read More
                  </NavLink>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4 my-2">
              <div className="card">
                <img
                  src="https://img1.wsimg.com/isteam/ip/ea607c1e-4afe-4532-98df-4b0ad7c2cd64/DSC_0078.JPG/:/cr=t:25.01%25,l:0%25,w:100%25,h:74.99%25/rs=w:388,h:194,cg:true"
                  alt=""
                  className="card-img-top"
                />
                <div className="card-body">
                  <h5>Plastic Moulding</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum, atque. Lorem ipsum dolor sit, amet consectetur
                    adipisicing elit. Sed, libero.
                  </p>
                  <button className="btn btn-primary rounded-5 my-3">
                    Read More
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* end to end services */}
      <div className="container text-center p-3 ">
        <h2 className="my-4 text-uppercase">End To End Services</h2>
        <hr />
        <img src="/images/s.jpg" alt="" className="img-fluid" />
      </div>

      {/* certificates section */}
      <div className="container mt-5">
        <div>
          <div className="row">
            <div className="col-md-6 p-2 align-self-center">
              <h2 className=" text-uppercase">Certifications and Compliance</h2>
              <hr />
              CKI Rubber Technologies (I) Pvt Ltd is proud to hold
              industry-recognized certifications that validate our commitment to
              quality and sustainability. Our adherence to international
              standards underscores our dedication to providing products that
              meet global benchmarks and comply with regulatory requirements.
            </div>
            <div className="col-md-6 text-center">
              <img
                src="/images/iso.png"
                alt=""
                className="img-fluid"
                width={300}
              />
            </div>
          </div>
        </div>
        {/* <Marquee pauseOnHover speed={50}>
          <div>
            <img
              src="https://img.freepik.com/premium-vector/iso-9001-2015-certification-iso-90012015-logo-iso-9000-certification_526569-642.jpg"
              className="m-5 rounded-circle"
              alt=""
              width="130"
              hieght="130"
            />
          </div>
          <div>
            <img
              src="https://img.freepik.com/premium-vector/iso-9001-2015-certification-iso-90012015-logo-iso-9000-certification_526569-642.jpg"
              className="m-5"
              alt=""
              width="130"
              hieght="130"
            />
          </div>

          <div>
            <img
              src="https://img.freepik.com/premium-vector/iso-9001-2015-certification-iso-90012015-logo-iso-9000-certification_526569-642.jpg"
              className="m-5"
              alt=""
              width="130"
              hieght="130"
            />
          </div>
          <div>
            <img
              src="https://img.freepik.com/premium-vector/iso-9001-2015-certification-iso-90012015-logo-iso-9000-certification_526569-642.jpg"
              className="m-5"
              alt=""
              width="130"
              hieght="130"
            />
          </div>
          <div>
            <img
              src="https://img.freepik.com/premium-vector/iso-9001-2015-certification-iso-90012015-logo-iso-9000-certification_526569-642.jpg"
              className="m-5"
              alt=""
              width="130"
              hieght="130"
            />
          </div>
        </Marquee> */}
      </div>
    </>
  );
};

export default Home;
